<template>
  <div>

    <h1 class="text-2xl font-bold mb-5">Business Loans</h1>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable :data="loans" :columns="columns" :loading="loading" :query="searchQuery" ref="table" />
    </div>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        searchQuery: '',
        company: null,
        loading: false,
        loans: [],
        columns: [
          {
            name: 'loan_type',
            th: 'Loan Type',
            render: (loan, type) => {
              switch (type) {
                case 'pay_day':
                  return 'Pay Day Loan';
                case 'standard':
                  return 'Standard Loan';
                default: 
                  return type;
              }
            }
          },
          {
            name: 'loan_amount',
            th: 'Loan Amount',
            render: (loan, loan_amount) => `₦${this.$options.filters.currency(loan_amount)}`
          },
          {
            name: 'created_at',
            th: 'Loan Date',
            render: (loan, created_at) => this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia')
          },
          {
            name: 'requested_amount',
            th: 'Requested Amount',
            render: (loan, requested_amount) =>  `₦${this.$options.filters.currency(requested_amount)}`
          },
          {
            name: 'tenure',
            th: 'Tenure',
            render: (loan, tenure) => `${tenure} Months`
          },
          {
            name: 'repayed',
            th: 'Total Repayed',
            render: () => '₦0.00'
          },
          {
            name: 'status',
            th: 'Status',
            render: (company, status) => {
              switch (status) {
                case 'approved':
                  return `<div class="badge badge-green-soft-outline">
                    Confirmed
                  </div>`;
                case 'disbursed':
                  return `<div class="badge badge-green-soft-outline">
                    Disbursed
                  </div>`;
                case 'declined':
                  return `<div class="badge badge-red-soft-outline">
                    Declined
                  </div>`;
                case 'paid':
                  return `<div class="badge badge-green-soft-outline">
                    Paid
                  </div>`;
                case 'pending':
                default:
                  return `<div class="badge badge-orange-soft-outline">
                    Pending
                  </div>`;
              }
            }
          },
        ]
      }
    },
    beforeMount() {
    },
    methods: {
      async getLoans() {
      }
    }
  }
</script>